@font-face {
  font-family: 'BM-Biotif';
  src: url('./fonts/bm-biotif/BM-Biotif-Bold.eot'), url('./fonts/bm-biotif/BM-Biotif-Bold.woff2') format('woff2'),
  url('./fonts/bm-biotif/BM-Biotif-Bold.woff') format('woff'),
  url('./fonts/bm-biotif/BM-Biotif-Bold.ttf') format('truetype'),
  url('./fonts/bm-biotif/BM-Biotif-Bold.svg#BM-Biotif-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'),
  local('Roboto-Regular'),
  url(https://fonts.gstatic.com/s/roboto/v15/QHD8zigcbDB8aPfIoaupKOvvDin1pK8aKteLpeZ5c0A.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  color: #320B42;
  font-family: 'Roboto', sans-serif;
  background-color: #F2F2F2;
  display: flex;
}

#root {
  display: flex;
  flex-grow: 1;
}

img {
  margin: 0;
}

h1 {
  font-family: BM-Biotif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.466667px;
  margin-bottom: 24px;
}

p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  text-align: center;
  letter-spacing: 0.28px;

  color: rgba(30, 13, 45, 0.8);
}

.bymiles-content {
  max-width: 288px;
  text-align: center;
  margin: 0 auto 64px auto;
  display: flex;
  flex-direction: column;

  .artwork {
    margin-bottom: 50px;
    max-width: 100%;
    height: auto;
  }

  h1 {
    margin-bottom: 20px;
  }
}

.bymiles-hello {
  margin: 86px 0 43px 0;

  h1 {
    margin-top: 50px;
  }

  p {
    line-height: 24px;
    margin-bottom: 20px;
  }

  .oem-logo {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
  }
}

.bymiles-permissions {
  flex-grow: 1;
}

.bymiles-connect {
  padding-top: 144px;
  flex-grow: 1;

  p {
    margin-bottom: 48px;
  }

  .bymiles-connect-oem {
    display: flex;
    justify-content: center;

    span {
      display: flex;
      background: white;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }

    :nth-child(2) {
      background: transparent;
    }
  }
}

.bymiles-permissions {
  padding-top: 92px;
  flex-grow: 1;

  p {
    margin: 24px 0;
  }

  .bymiles-permission {
    display: block;
    background: white;
    border-radius: 8px;
    padding: 14px 16px 14px 16px;
    margin: 0 0 16px 0;
    text-align: left;

    img {
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
}

.bymiles-feed-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #320B42;
  align-items: center;
  flex-grow: 1;

  p {
    color: white;
  }
}
.bymiles-error,
.bymiles-success {
  padding-top: 181px;
  flex-grow: 1;

  p {
    line-height: 24px;
  }
}

.bymiles-spinner {
  img {
    width: 40px;
    height: 40px;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
  }

  p {
    margin-top: 20px;
  }

  @-moz-keyframes spin {
    100% { -moz-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
    }
  }
}
